import { graphql } from '@/core/services/graphql-api.service';
import { Trip } from '@/interfaces/trip';

export const getAllTrips = (): Promise<Trip[]> => {
    const q = `
    query {
        trips {
            id
            name
            description
            createdAt
            updatedAt
            startDate
            endDate
        }
    }`;
    return graphql<{ trips: Trip[] }>({ query: q }).then((res) => res.trips);
};

export const getTripById = (id: string): Promise<Trip> => {
    const q = `
    query GetTripById($id: String!) { 
        trip(id: $id) {
            id
            name
            description
            startDate
            endDate
        }
    }
  `;
    return graphql<{ trip: Trip }>({
        query: q,
        variables: { id: id }
    }).then((res) => res.trip);
};

export const createTrip = (name: string, description: string, startDate: string, endDate: string): Promise<Trip> => {
    const q = `
    mutation CreateTrip($payload: CreateTripInput!) {
        createTrip(payload: $payload) {
            id
        }
    }`;
    return graphql<{ trip: Trip }>({
        query: q,
        variables: { payload: { name: name, description: description, startDate: startDate, endDate: endDate } }
    }).then((res) => res.trip);
};

export const updateTrip = (id: string, name: string, description: string, startDate: string, endDate: string): Promise<Trip> => {
    const q = `
    mutation UpdateTrip($payload: UpdateTripInput!) {
        updateTrip(payload: $payload) {
            id
        }
    }`;
    return graphql<{ trip: Trip }>({
        query: q,
        variables: { payload: { id: id, name: name, description: description, startDate: startDate, endDate: endDate } }
    }).then((res) => res.trip);
};

export const deleteTrip = (id: string): Promise<Trip> => {
    const q = `
    mutation Deleterip($id: String!) {
        deleteTrip(id: $id) {
            id
        }
    }
  `;
    return graphql<{ trip: Trip }>({ query: q, variables: { id: id } }).then(
        (res) => res.trip
    );
};
