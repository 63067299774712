<template>
    <div v-if="trips">
        <table>
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Description</th>
                    <th>Started at</th>
                    <th>Ended at</th>
                    <th>Created at</th>
                    <th>Last Updated at</th>
                    <th>Actions</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="trip in trips" :key="trip.id">
                    <td>{{ trip.name }}</td>
                    <td>{{ trip.description }}</td>
                    <td>{{ trip.startDate }}</td>
                    <td>{{ trip.endDate }}</td>
                    <td>{{ trip.createdAt }}</td>
                    <td>{{ trip.updatedAt }}</td>
                    <td>
                        <button @click="editTrip(trip.id)">Edit</button>
                        <button @click="deleteTrip(trip.id)">Delete</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script lang="ts">
import { Trip } from '@/interfaces/trip';
import { getAllTrips, deleteTrip } from '@/services/trips.service';
import { defineComponent } from '@vue/runtime-core';
export default defineComponent({
    data() {
        return {
            trips: undefined as Trip[] | undefined
        };
    },

    async created() {
        await this.getTrips();
    },

    methods: {
        async getTrips() {
            this.trips = await getAllTrips();
        },

        async deleteTrip(id: string) {
            await deleteTrip(id);
            await this.getTrips();
        },

        editTrip(id: string): void {
            this.$router.push(`/trips/edit/${id}`);
        }
    }
});
</script>
