
import { Trip } from '@/interfaces/trip';
import { getAllTrips, deleteTrip } from '@/services/trips.service';
import { defineComponent } from '@vue/runtime-core';
export default defineComponent({
    data() {
        return {
            trips: undefined as Trip[] | undefined
        };
    },

    async created() {
        await this.getTrips();
    },

    methods: {
        async getTrips() {
            this.trips = await getAllTrips();
        },

        async deleteTrip(id: string) {
            await deleteTrip(id);
            await this.getTrips();
        },

        editTrip(id: string): void {
            this.$router.push(`/trips/edit/${id}`);
        }
    }
});
