import axios from 'axios';

export class ConfigService {
    private static _settings: Map<string, string> = new Map<string, string>();

    async getConfigSetting(key: string): Promise<string | undefined> {
        try {
            if (ConfigService._settings.has(key)) {
                return ConfigService._settings.get(key);
            } else {
                const setting = await axios.get(`api/config?key=${key}`);
                ConfigService._settings.set(key, setting.data)
                return ConfigService._settings.get(key);
            }
        } catch {
            console.error(`Failed to load config setting for key '${key}'`);
        }
    }
}
