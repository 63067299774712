<template>
  <router-link to="/trips/edit">Create Trip</router-link>
  <TripList />
</template>

<script lang="ts">
import TripList from "@/views/TripList.vue";
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
  components: {
    TripList,
  },
});
</script>
