<template>
    <div class="text-center">
        <Navbar />
        <router-view />
    </div>
</template>

<script lang="ts">
import { defineComponent } from '@vue/runtime-core';
import Navbar from './components/Navbar.vue';

export default defineComponent({
    components: {
        Navbar
    }
});
</script>
