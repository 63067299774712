import { post } from '@/core/services/base-api.service';

const url = '/graphql';

type GraphQLDataType = {
    query: string;
    variables?: { [key: string]: any };
};

export const graphql = <T>(graphqlData: GraphQLDataType): Promise<T> => {
    return post<{ data: T }, string>(url, JSON.stringify(graphqlData)).then(
        (res: { data: T }) => res.data
    );
};
